<template>
  <v-container fluid>
    <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <v-card-title class="pa-4 accent">
          <v-btn
            :to="{ path: '/notificacoes' }"
            small
            exact
            fab
            text
            class="mr-2"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-icon color="primary" left>mdi-feed</v-icon>
          {{ feed.mensagem }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <h1 class="pa-4 text-h5 font-weight-bold">Dados Gerais</h1>

          <v-divider class="mb-6"></v-divider>
          <v-row>
            <v-col cols="12">
              <v-row dense class="px-4">
                <!-- Envio Imediato -->
                <v-col v-if="!feed_original.envio_imediato" cols="12" md="4">
                  <v-text-field dense readonly value="Envio Imediáto:">
                    <template v-slot:append>
                      <v-switch
                        v-model="feed.envio_imediato"
                        :false-value="false"
                        :true-value="true"
                        class="mt-0 pt-0"
                        :disabled="feed.status === 1 ? true : false"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                </v-col>

                <!-- Mensagem -->
                <v-col cols="12">
                  <v-textarea
                    v-model="feed.mensagem"
                    label="Mensagem"
                    required
                    :disabled="feed.status === 1 ? true : false"
                    :rules="formRules"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-btn :to="{ path: '/notificacoes' }" exact color="primary">
            <v-icon text class="mr-2" left>mdi-arrow-left</v-icon>
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="updateFeed"
            :disabled="!validForm || feed.status === 1"
            color="save"
            class="white--text"
          >
            <v-icon left>mdi-content-save</v-icon>
            salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { update as putFeed, show as fetchFeed } from "@/api/admin/feed.js";
import { inputRequired } from "@/plugins/utils.js";
import { mapState } from "vuex";

export default {
  name: "Feed",

  data() {
    return {
      feed: {},
      feed_original: {},
      loading: false,
      validForm: true,
      formRules: [inputRequired],
    };
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),

    notificar_types() {
      return ["PUSH", "FEED"];
    },
    feed_id() {
      return this.$route.params.feedId;
    },
  },

  watch: {},

  methods: {
    updateFeed() {
      if (JSON.stringify(this.feed) === JSON.stringify(this.feed_original)) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        const feed = new FormData();

        let feed_2 = {};
        feed_2.mensagem = this.feed.mensagem;
        feed_2.envio_imediato = this.feed.envio_imediato;
        if (this.feed.envio_imediato === true) {
          feed_2.status = 1;
        }

        feed_2.user_id = this.user_id;

        for (let key in feed_2) {
          if (
            feed_2[key] !== null &&
            feed_2[key] !== undefined &&
            feed_2[key] !== ""
          ) {
            feed.append(key, feed_2[key]);
          }
        }

        putFeed(this.feed_id, feed)
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success("Atualizado com sucesso");
              this.loading = false;
              this.$router.push({ path: "/notificacoes" });
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    getFeed() {
      this.loading = true;
      fetchFeed(this.feed_id)
        .then((response) => {
          this.feed = response;
          this.feed_original = { ...response };
          this.imagem = this.feed.imagem_url;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getFeed();
  },
};
</script>

<style lang="scss" scoped></style>
