import api from "../axios_service.js";
import apiMessages from "../messages/axios_service.js";

export async function index(filtros) {
  const response = await api.get(`/admin/feed${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function show(id) {
  const response = await api.get(`/admin/feed/${id}`);
  return response.data.result;
}

export function update(id, feed) {
  return api.post(`/admin/feed/${id}`, feed);
}

export function create(feed) {
  return apiMessages.post(`/send-push-notification-to-topic`, feed);
}